var site = site || {};
var generic = generic || {};

(function ($) {
  site.cartConfirm = site.cartConfirm || {};

  var template = $('script.inline-template[path="cart_confirm"]').html();
  var $cartConfirmWrapper = $(
    '#header .block.block-nodeblock[class*="cart"] .dd-content, #header-gnav-navigation .js-header-gnav-cart__content-wrapper'
  );
  var $cartConfirm = $('.gnav-cart-v1', $cartConfirmWrapper);
  // New for 2019 Redesign - panel slideout
  var $cartPanelOverlay = $cartConfirmWrapper.closest('.js-header-gnav-cart__overlay');
  var userCartCount = site.userInfoCookie.getValue('item_count');
  // Used to iterate offer types from rpc call
  var offerLabels = ['offers_qualified', 'offers_not_qualified', 'offers_in_cart'];
  var offerTab = 'offers';

  site.cartConfirm.prepareCart = function (cartResult) {
    var $cartMiniProductOverlay = $(
      '.overlay-container  .cart-mpp-product-overlay-content .mpp-product, .overlay-container .module-spp-detail'
    );

    if ($cartMiniProductOverlay.length === 0) {
      generic.overlay.hide();
    }
    $cartConfirm.removeClass('empty');
    if (typeof cartResult.trans_data === 'undefined') {
      return;
    }
    var args = {
      trans: cartResult.trans_data,
      items: cartResult.trans_data.order.items,
      newItemID:
        typeof cartResult.ac_results !== 'undefined'
          ? cartResult.ac_results[0].result.CARTITEM['prod.PRODUCT_ID'] +
            cartResult.ac_results[0].result.CARTITEM['sku.SKU_ID']
          : undefined
    };

    site.userTransObj.setTransObject(cartResult.trans_data);
    site.cartConfirm.getOffersData(function () {
      site.cartConfirm.launch(args);
    });
    $cartConfirmWrapper.addClass('active');
    var gnavSub = $cartConfirmWrapper.find('.gnav-item-content');

    if ($('html').hasClass('no-csstransitions')) {
      gnavSub.fadeIn('slow');
    }
    if ($cartPanelOverlay.length) {
      $cartConfirmWrapper.addClass('active'); // CONNAVFO-1961 sometimes this is being missed
      $cartPanelOverlay.addClass('visible');
      $('body').addClass('active-gnav');
      if (site && site.track) {
        site.track.cartOverlay();
      }
    }
    $('.last-item-added', $cartConfirmWrapper)
      .animate(
        {
          backgroundColor: $.Color({ alpha: 0 })
        },
        800
      )
      .removeClass('last-item-added');
  };

  site.cartConfirm.display = function (cartResult, noDisplayOnEmptyCart) {
    if (!cartResult?.order || (cartResult.order.items.length < 1 && cartResult.items_count < 1)) {
      $cartConfirm.addClass('empty');

      if (noDisplayOnEmptyCart) {
        return;
      }
    } else {
      $cartConfirm.removeClass('empty');
    }

    var args = {
      trans: cartResult,
      items: cartResult.order.items
    };

    site.cartConfirm.getOffersData(function () {
      site.cartConfirm.launch(args);
    });
  };

  site.cartConfirm.launch = function (args) {
    var self = this;
    var trans = args.trans;
    var order = trans.order;
    var cartItems = args.items.concat(order.samples);
    var totals = typeof trans.trans != 'undefined' ? trans.trans.totals : trans.totals;
    var templateData = { items: [] };
    var cartItem;
    var cartItemsCount = cartItems.length;
    var $cartConfirmWrapperTitle = $();

    // Normalize key names in cartItem object. 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
    for (var i = 0; i < cartItemsCount; i++) {
      cartItem = cartItems[i];
      var templateItem = {};

      for (var key in cartItem) {
        if (cartItem.hasOwnProperty(key)) {
          templateItem[key.replace(/\./, '_')] = cartItem[key];
        }
      }
      if (templateItem.prod_USES_SKU_IMAGES === 1) {
        templateItem.prod_THUMBNAIL_IMAGE = templateItem.sku_THUMBNAIL_IMAGE;
        if ($cartPanelOverlay.length) {
          // larger image for 2019 redesign
          templateItem.prod_IMAGE_STICKY = templateItem.sku_THUMBNAIL_IMAGE;
        } else {
          templateItem.prod_IMAGE_STICKY = templateItem.sku_IMAGE_STICKY;
        }
      }
      if (templateItem.prod_USES_SKU_IMAGES !== 1 && $cartPanelOverlay.length) {
        // larger image for 2019 redesign
        templateItem.prod_IMAGE_STICKY = templateItem.prod_THUMBNAIL_IMAGE;
      }
      if (templateItem.itemType === 'SampleItem') {
        templateItem.isSampleItem = 1;
      } else {
        templateItem.isSampleItem = 0;
      }

      if (templateItem.itemType === 'KitItem') {
        templateItem.isKitItem = 1;
        templateItem.isCustomKitItem = 0;
        templateItem = self.buildKitItemData(templateItem);
      } else {
        templateItem.isKitItem = 0;
      }
      if (templateItem.itemType === 'CustomKitItem') {
        templateItem.isCustomKitItem = 1;
        templateItem.isKitItem = 0;
        templateItem = self.buildCustomKitItemData(templateItem);
      } else {
        templateItem.isCustomKitItem = 0;
      }

      templateData.items[i] = templateItem;
    }
    templateData.offerData = {};
    if (self.offers) {
      var offersArr = self.offers.sort((a, b) => a.sort_order - b.sort_order);

      offersArr
        .map((r) => {
          if (!r.offers_tab_location || r.offers_tab_location.trim() === '') {
            r.offerLocation = [offerTab];
          } else {
            r.offerLocation = r.offers_tab_location.split(',').sort();
          }

          return r;
        })
        .forEach((offer) => {
          offer.offerLocation.forEach((location) => {
            if (!templateData.offerData[location]) {
              templateData.offerData[location] = {};
              templateData.offerData[`${location}Count`] = 0;
            }
            if (!templateData.offerData[location][offer.offerLabel]) {
              templateData.offerData[location][offer.offerLabel] = [];
            }
            templateData.offerData[location][offer.offerLabel].push(offer);
            templateData.offerData[`${location}Count`]++;
          });
        });
    }

    templateData.formattedSubtotal = trans.formattedSubtotal;
    templateData.items_count = trans.items_count;
    templateData.offers_count = templateData.offerData[`${offerTab}Count`] || 0;
    $cartConfirm.html(window.Mustache.render(template, templateData));

    // Assign ::FIRST_NAME:: placeholder after template renders
    var firstName = '';

    if (
      Number(site.userInfoCookie.getValue('signed_in')) === 1 ||
      Number(site.userInfoCookie.getValue('csr_logged_in')) === 1
    ) {
      firstName = site.userInfoCookie.getValue('first_name');
    }
    $('.js-personal-message').each(function () {
      $(this).text(
        $(this)
          .text()
          .replace('::FIRST_NAME::', firstName ? ` ${firstName}` : '')
      );
    });

    $('a.close, .buttons .left-copy.continue a', $cartConfirm).on('click', function (e) {
      e.preventDefault();

      // updated for 2019 redesign
      if (!$('body').hasClass('active-gnav')) {
        $cartConfirmWrapper.removeClass('active');
      }

      if ($('html').hasClass('no-csstransitions')) {
        var gnavSub = $cartConfirmWrapper.find('.gnav-item-content');

        gnavSub.fadeOut('slow');
      }
    });

    $('.js-product-row-info-remove', $cartConfirm).on('click', function (e) {
      e.preventDefault();

      var $self = $(this);

      // Check if the product to remove is collection
      if ($self.data('collection-id')) {
        site.cartConfirm.removeItemCollection($self);
      } else {
        site.cartConfirm.removeItem($self);
      }
    });

    if (templateData.items_count !== 1) {
      $('.items', $cartConfirm).addClass('plural');
    }

    // If an item was just added, move it to the top of the products and give it a special class
    if (typeof args.newItemID !== 'undefined') {
      // Avoid Duplicate Gift Card SKU's #182184
      $('.prod-' + args.newItemID, $cartConfirm)
        .first()
        .addClass('last-item-added')
        .prependTo('.cart-overlay-products');
    }

    // Dynamically add the last item added title to the cart confirm wrapper heading
    $cartConfirmWrapperTitle = $cartConfirmWrapper.find('.cart-confirm-wrapper h2 span.item-added');

    // show link based offer message to user if there is an link based offer active
    if ($.cookie('offer_info') !== '' && !!$.cookie('offer_info')) {
      var offerCode = $.cookie('offer_info').split(':')[1];

      generic.jsonrpc.fetch({
        method: 'offer.linkedOfferState',
        params: [offerCode],
        onSuccess: function (r) {
          var offer = r.getValue();
          var isActive = !!offer.linked_offer && offer.linked_offer.length !== 0;

          if (isActive) {
            var linkOfferMessage =
              offer.linked_offer.offer_message.length !== 0 ? offer.linked_offer.offer_message : '';

            $cartConfirmWrapperTitle.html(linkOfferMessage);
          }
        },
        onFailure: function () {}
      });
    } else {
      cartMessage();
    }

    function cartMessage() {
      // show free shipping message to user if there is an offer active
      if (trans.offerOverlayMessage !== '') {
        $cartConfirmWrapperTitle.html(trans.offerOverlayMessage);
      } else {
        // show the normal add to bag success message
        if (typeof args.newItemID !== 'undefined') {
          $cartConfirmWrapperTitle.html($('[data-localtrans="cart_confirm_success_msg"]').html());
        } else {
          $cartConfirmWrapperTitle.html(
            $('span[data-localtrans="cart_confirm_you_have"]').html() +
              ' ' +
              templateData.items_count +
              ' ' +
              (templateData.items_count === 1
                ? $('[data-localtrans="cart_confirm_item"]').html()
                : $('[data-localtrans="cart_confirm_items"]').html()) +
              ' ' +
              $('[data-localtrans="cart_confirm_in_bag"]').html()
          );
        }
      }
    }

    // Sort out the shade info areas
    $('.shade-thumb[data-hex!=""]', $cartConfirmWrapper).each(function () {
      var $my = $(this);
      var hex = $my.attr('data-hex');
      var shades = hex.split(',');

      $my.parent().removeClass('hidden');
      if (shades.length > 1) {
        $my.attr('style', '');
        for (var i = 0; i < shades.length; i++) {
          $my.append(
            '<div class="shade-thumb_' +
              shades.length +
              '" style="background-color: #' +
              shades[i] +
              '"></div>'
          );
        }
      }
    });

    if (!Drupal.settings.globals_variables.disable_float_price) {
      $('.shipping span', $cartConfirm).html(parseFloat(totals.SHIPPING_W_TAX).toFixed(2));
      $('.total span', $cartConfirm).html(parseFloat(totals.TOTAL_COST_W_TAX).toFixed(2));
      $('.subtotal_output span', $cartConfirm).html(
        parseFloat(totals.PRODUCT_SUM_W_TAX).toFixed(2)
      );
    } else {
      $('.shipping span', $cartConfirm).html(parseInt(totals.SHIPPING_W_TAX) + ' ');
      $('.total span', $cartConfirm).html(parseInt(totals.TOTAL_COST_W_TAX) + ' ');
      $('.subtotal_output span', $cartConfirm).html(totals.PRODUCT_SUM_W_TAX_FORMATTED + ' ');
    }

    var is_anonymous_user = site.userInfoCookie.getValue('signed_in') !== '1';

    if (!is_anonymous_user && Drupal.settings.cart_loyalty_prices_enabled) {
      $(document).trigger('productQV:rendered:LoyaltyPrices', $('.cart-confirm-wrapper'));
    }

    // close confirm overlay on scroll (bug#180737)
    $(window).on(
      'scroll',
      _.throttle(function () {
        $('.cart-confirm-wrapper a.close', $cartConfirm).trigger('click');
      }, 100)
    );

    $('.js-cart-overlay-tab', $cartConfirm).on('click', function () {
      var tabName = $(this).data('tab-name');

      $('.js-cart-overlay-tab', $cartConfirm).removeClass('selected');
      $(this).addClass('selected');
      $('.js-cart-overlay-tab-content[data-tab-name="' + tabName + '"]', $cartConfirm).removeClass(
        'hidden'
      );
      $('.js-cart-overlay-tab-content')
        .not('[data-tab-name="' + tabName + '"]', $cartConfirm)
        .addClass('hidden');
    });
  };

  /*
   * Build derived fields for collection type items
   */
  site.cartConfirm.buildCustomKitItemData = function (item) {
    var prodName = [];
    var baseImg = '';
    var itemProdName = item.prod_PROD_RGN_NAME;
    if (item.ITEM_QUANTITY === null) {
      item.ITEM_QUANTITY = 1;
    }
    item.kit_formattedPrice = item.basePrice.toFixed(2);
    if (item.items.length) {
      baseImg = item.items[0]['sku.THUMBNAIL_IMAGE'];
      item.items.map(function (item) {
        var itemsProdSkuName = item['prod.PROD_RGN_NAME'].trim();

        if (itemProdName !== itemsProdSkuName) {
          prodName.push(itemsProdSkuName);
        }
      });
      var prod_giftWrap = prodName.indexOf('Gift Wrap');
      if (prod_giftWrap > -1) {
        prodName.splice(prod_giftWrap, 1);
      }
      var customKit = prodName.indexOf('Custom Kit');
      if (customKit > -1) {
        prodName.splice(customKit, 1);
      }
      var customGift = prodName.indexOf('Custom Gift');

      if (customGift > -1) {
        prodName.splice(customGift, 1);
      }
      item.prodName1 = prodName[0];
      item.prodName2 = prodName[1];
      item.prodName3 = prodName[2];
      item.kitStickyImage = baseImg;
    }
    return item;
  };
  site.cartConfirm.buildKitItemData = function (item) {
    var kitName = [];
    var kitImageProductCode = [];
    var baseImg = '';
    var baseProductCode = '';
    var thumbImage;

    item.isKitItem = 1;
    if (item.ITEM_QUANTITY === null) {
      item.ITEM_QUANTITY = 1;
    }
    item.kit_formattedPrice = item.basePrice.toFixed(2);
    if (item.items.length) {
      thumbImage = item.items[0]['sku.THUMBNAIL_IMAGE'];
      if ($cartPanelOverlay.length > 0 && typeof thumbImage !== 'undefined') {
        baseImg = thumbImage;
      } else {
        baseImg = item.items[0]['sku.IMAGE_STICKY'];
      }

      baseProductCode = item.items[0]['sku.PRODUCT_CODE'];

      item.items.map(function (item) {
        // since only one of the components will have the .Net field set
        // we use it as flag to determine the order we will build the collection image + name
        if (item['prod.GIFTSET_COMPONENTS']) {
          kitName.push(item['prod.PROD_RGN_NAME'].trim());
          kitImageProductCode.push(item['sku.PRODUCT_CODE']);
        } else {
          kitName.unshift(item['prod.PROD_RGN_NAME'].trim());
          kitImageProductCode.unshift(item['sku.PRODUCT_CODE']);
        }
      });

      item.kitName = kitName.join(' + ');
      item.kitStickyImage = baseImg.replace(baseProductCode, kitImageProductCode.join('_'));
    }

    return item;
  };

  site.cartConfirm.getTransData = function (callback) {
    generic.jsonrpc.fetch({
      method: 'trans.get',
      params: [
        {
          trans_fields: ['TRANS_ID', 'totals'],
          payment_fields: [],
          order_fields: ['items']
        }
      ],
      onSuccess: function (result) {
        var cartResult = {};

        cartResult.trans_data = result.getValue();
        $(document).trigger('trans_data.loaded', cartResult);
        if (typeof callback === 'function') {
          callback(cartResult);
        }
        site.loading.hide();
      }
    });
  };

  site.cartConfirm.getOffersData = function (cb) {
    var self = this;
    var matching = {
      JSONRPC: JSON.stringify([
        {
          method: 'offer.offersMatching',
          id: 1,
          params: [
            {
              filter: {
                meetsCheckout: 1,
                not_in_cart: 1,
                promotion: 'show_in_cart_overlay'
              },
              get_rpc_data: {
                get_products: 1,
                get_possibility: 1,
                get_drupal_node: ['offers_tab_node_id', 'offers_tab_image']
              }
            }
          ]
        },
        {
          method: 'offer.offersMatching',
          id: 2,
          params: [
            {
              filter: {
                not_meetsCheckout: 1,
                timing: 'active',
                promotion: 'show_in_cart_overlay'
              },
              get_rpc_data: {
                get_products: 1,
                get_possibility: 1,
                get_drupal_node: ['offers_tab_node_id', 'offers_tab_image']
              }
            }
          ]
        },
        {
          method: 'offer.offersMatching',
          id: 3,
          params: [
            {
              filter: {
                in_cart: 1,
                promotion: 'show_in_cart_overlay'
              },
              get_rpc_data: {
                get_products: 1,
                get_possibility: 1,
                get_drupal_node: ['offers_tab_node_id', 'offers_tab_image']
              }
            }
          ]
        }
      ])
    };

    $.ajax({
      type: 'POST',
      url: '/rpc/jsonrpc.tmpl',
      data: matching,
      success: function (data) {
        self.offers = [];
        data.forEach(function (response) {
          var label = offerLabels[response.id - 1];

          if (!!response.result.value && !!response.result.value.offers) {
            var value = response.result.value;

            for (var offerCode in value.offers) {
              if (!value.offers.hasOwnProperty(offerCode)) {
                continue;
              }
              var offer = value.offers[offerCode];

              offer['offerLabel'] = label;

              if (!!value.drupalNodeContent && !!value.drupalNodeContent[offerCode]) {
                var nodeContent = value.drupalNodeContent[offerCode];

                if (!!nodeContent.offers_tab_node_id) {
                  offer.offers_tab_node_content = nodeContent.offers_tab_node_id;
                }
                if (!!nodeContent.offers_tab_image) {
                  offer.offers_tab_image_content = nodeContent.offers_tab_image;
                }
              }

              if (!!value.offersProducts) {
                offer.products = value.offersProducts;
              }

              offer.offers_tab_image = self.getOfferImage(offer);

              if (
                !!offer.criteria &&
                offer.criteria.length &&
                !!value.offersPossible &&
                !!value.offersPossible[offer.offer_code]
              ) {
                for (var i = 0; i < offer.criteria.length; i++) {
                  var criteria = offer.criteria[i];
                  var offerCriteriaPossible =
                    value.offersPossible[offer.offer_code][criteria['class']];

                  if (!!offerCriteriaPossible && !!offerCriteriaPossible.threshold_message) {
                    criteria.threshold_message = offerCriteriaPossible.threshold_message;
                  }
                }
              }
              self.offers.push(offer);
            }
          }
        });

        if (!!cb) {
          cb(data);
        }
      }
    });
  };

  site.cartConfirm.getOfferImage = function (offer) {
    var self = this;

    if (!!offer.offers_tab_image) {
      return {
        src: offer.offers_tab_image,
        alt: offer.offers_tab_description || ''
      };
    }

    var offerHasSku =
      !!self.offersProducts &&
      Object.keys(self.offersProducts).length &&
      Object.keys(self.offersProducts.skus).length &&
      self.offersProducts.skus[offer.offer_code] &&
      self.offersProducts.skus[offer.offer_code].length;

    if (offerHasSku) {
      var sku = self.offersProducts.skus[offer.offer_code][0];
      var product = self.offersProducts.products[sku.PRODUCT_ID];

      return {
        src: product.SMALL_IMAGE,
        alt: product.PROD_RGN_NAME
      };
    }
    if (!offer.image) {
      return {};
    }
  };

  site.cartConfirm.clearUrl = function () {
    window.location = window.location.href.replace(window.location.search, '');
  };

  site.cartConfirm.removeItem = function ($item) {
    var params = {
      _SUBMIT: 'cart',
      SKU_BASE_ID: $item.data('skuBaseId'),
      CART_ID: $item.data('cartId'),
      QTY: 0
    };

    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [params],
      onSuccess: function (response) {
        var cartResult = response.getData().trans_data;

        site.cartConfirm.display(cartResult, false);
        if (site.offerKitMultiSku && site.offerKitMultiSku.deSelection) {
          site.offerKitMultiSku.deSelection($item.data('skuBaseId'));
        }
        $(document).trigger('cart.updated', response.getData());
      },
      onFailure: function (jsonRpcResponse) {
        var errors = jsonRpcResponse.getMessages();
        var resultData = jsonRpcResponse.getData();
        var transData = resultData?.trans_data;

        $(document).trigger('removeToCart.failure', [errors, resultData]);

        if (resultData?.ac_results[0]?.result.SUCCESS && transData) {
          site.cartConfirm.display(transData, false);
          $(document).trigger('cart.updated', resultData);
        }
      }
    });
  };

  site.cartConfirm.removeItemCollection = function ($item) {
    var params = {
      cmode: 'delete',
      _SUBMIT: 'cart',
      CART_ID: $item.data('cartId'),
      QTY: 0,
      COLLECTION_ID: $item.data('collectionId'),
      COLLECTION_NAME: $item.data('collectionName'),
      COLLECTION_TYPE: $item.data('collectionType')
    };

    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [params],
      onSuccess: function () {
        site.cartConfirm.getTransData(function (cartResult) {
          site.cartConfirm.display(cartResult.trans_data, false);
          $(document).trigger('cart.updated', cartResult);
        });
      },
      onFailure: function (jsonRpcResponse) {
        var errors = jsonRpcResponse.getMessages();
        var resultData = jsonRpcResponse.getData();

        $(document).trigger('removeToCart.failure', [errors, resultData]);
      }
    });
  };

  Drupal.behaviors.cartConfirm = {
    attach: function (context) {
      var $doc = $(document);

      $doc.on('addToCart.success', function (e, cartResult) {
        site.cartConfirm.prepareCart(cartResult);
      });
      $doc.on(
        'addToCartKitItem.success editCartKitItem.success updateSampleItems.success',
        function (e, args) {
          site.cartConfirm.getTransData(args.callback);
        }
      );

      $doc.on('cart.loaded', function (event, cartResult) {
        site.cartConfirm.display(cartResult, true);
      });

      // only load trans object if they mouseover cart icon
      $('.block-template-gnav-cart-v1, #sticky-bar .shopping-bag').hover(function () {
        if ($('.gnav-cart-v1 .cart-confirm-wrapper', context).length === 0) {
          site.userTransObj.getTransObject();
        }
      });

      // 2019 Redesign - panel on click not hover, only when we have a count
      $('#header-gnav-navigation .js-header-gnav-cart__trigger')
        .once()
        .on('click', function (e) {
          if (userCartCount.length && userCartCount > 0) {
            e.preventDefault();
            $cartPanelOverlay.addClass('visible');
            $('body').addClass('active-gnav');
            $cartConfirmWrapper.addClass('active');
            if ($('.gnav-cart-v1 .cart-confirm-wrapper', context).length === 0) {
              site.userTransObj.getTransObject();
            }
          }
        });
    }
  };

  var getObjects = function (obj, key, val) {
    var objects = [];

    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) {
        continue;
      }
      if (typeof obj[i] === 'object') {
        objects = objects.concat(getObjects(obj[i], key, val));
      } else if (i === key && obj[key] === val) {
        objects.push(obj);
      }
    }

    return objects;
  };
})(jQuery);
